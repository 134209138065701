.common_section {
  position: relative;
  overflow-x: clip;
}

.common_section .logo_big {
  height: 2200px;
  position: absolute;
  top: -394px;
  right: -1397px;
}

.main {
  position: relative;
}

.main .main_header {
  letter-spacing: -1px;
  font-size: 100px;
  line-height: 100px;
}

.main .underline {
  position: absolute;
  bottom: 5px;
}

.main ._light {
  font-weight: 300;
}

.main h1:last-of-type {
  z-index: 7;
  margin-bottom: 20px;
  position: relative;
}

.overlay {
  width: 100%;
  background-image: url("pattern-wave.a271c9d8.svg");
  background-position: center;
  background-repeat: repeat;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.overlay .main_subheader {
  padding-top: 24px;
  padding-bottom: 51px;
}

.overlay .main_subheader p {
  max-width: 676px;
  padding-bottom: 48px;
  font-weight: 400;
}

.more_about_link {
  text-decoration: none;
}

.more_about_link button {
  cursor: pointer;
  color: #131315;
  background-color: #fcd3af;
  border: none;
  border-radius: 2px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 17px 24px 16px;
  font-family: Lexend, sans-serif;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.more_about_link button img {
  height: 20px;
  width: 21px;
  margin-left: 8px;
}

.more_about_link button .us_text {
  display: none;
}

.together {
  padding-top: 125px;
}

.together .together_content {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.together .together_content .around-arrows {
  position: absolute;
  top: -4%;
  left: 33%;
}

.together .together_content .together_right_column {
  max-width: 374px;
  padding-bottom: 120px;
  position: relative;
}

.together .together_content .together_right_column .right_link_underline {
  max-width: 280px;
  position: absolute;
  bottom: -8px;
}

.together .together_content .together_left_column {
  max-width: 414px;
  padding-top: 120px;
  position: relative;
}

.together .together_content .together_left_column .left_link_underline {
  max-width: 384px;
  position: absolute;
  bottom: -8px;
}

.together .number_title {
  color: #1d1f24;
  text-shadow: 0 -2px #2f2f32, 0 -2px #2f2f32, 0 2px #2f2f32, 0 2px #2f2f32, -2px 0 #2f2f32, 2px 0 #2f2f32, -2px 0 #2f2f32, 2px 0 #2f2f32, -1px -2px #2f2f32, 1px -2px #2f2f32, -1px 2px #2f2f32, 1px 2px #2f2f32, -2px -1px #2f2f32, 2px -1px #2f2f32, -2px 1px #2f2f32, 2px 1px #2f2f32, -2px -2px #2f2f32, 2px -2px #2f2f32, -2px 2px #2f2f32, 2px 2px #2f2f32, -2px -2px #2f2f32, 2px -2px #2f2f32, -2px 2px #2f2f32, 2px 2px #2f2f32;
  margin-bottom: -33px;
  font-size: 140px;
  font-weight: 700;
  line-height: 140px;
}

.together .together_subtitle {
  letter-spacing: -.32px;
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
}

.together p {
  padding: 32px 0;
  font-weight: 400;
  line-height: 30px;
}

.products {
  padding-top: 128px;
  padding-bottom: 128px;
  position: relative;
}

.products .wiggle_arrow {
  position: absolute;
  top: 140px;
  right: 140px;
}

.products .products_content_container {
  max-width: 576px;
  padding-top: 32px;
  padding-bottom: 80px;
  font-weight: 500;
  line-height: 30px;
}

.products .products_menu {
  flex-direction: row;
  align-items: center;
  padding-bottom: 80px;
  display: flex;
}

.products .products_menu .active {
  color: #fcd3af;
}

.products .products_menu li {
  color: #bbbcbd;
  padding-right: 48px;
  font-weight: 300;
}

.products .products_menu li:last-of-type {
  padding-right: 0;
}

.products .products_list {
  flex-wrap: wrap;
  gap: 24px;
  display: flex;
}

.products .products_list li {
  flex: calc(33.3333% - 24px);
}

.products .products_list li img {
  max-width: 376px;
  max-height: 376px;
}

.products .products_list .product_name {
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.products .products_list .product_description {
  color: #bbbcbd;
  font-weight: 400;
}

.about {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
}

.about .stranlkraft-logo {
  max-width: 576px;
}

.about .about-subtitle {
  font-weight: 500;
  line-height: 30px;
}

.about .about-subtitle:first-of-type {
  padding-top: 32px;
}

.about .about-description {
  padding-top: 24px;
  font-weight: 400;
  line-height: 30px;
}

.about .about-list {
  flex-direction: row;
  justify-content: space-around;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 0;
  display: flex;
}

.about .about-list li {
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  display: flex;
}

.about .about-list .item_image {
  color: #fcd3af;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
}

.about .about-list .item_name {
  font-weight: 500;
}

.network > div:first-of-type {
  text-align: center;
}

.network .network_list {
  border-bottom: 1px solid #2f2f32;
  flex-flow: wrap;
  justify-content: space-between;
  padding-bottom: 32px;
  padding-left: 0;
  display: flex;
}

.network .network_list li {
  list-style-type: none;
}

.network .network_list img {
  max-height: 70px;
  padding-top: 24px;
}

.news {
  padding-top: 128px;
  padding-bottom: 128px;
}

.news .news-content {
  gap: 24px;
  display: flex;
}

.news .news-content > div {
  flex-direction: column;
  flex: calc(50% - 24px);
  justify-content: start;
  padding-top: 32px;
  display: flex;
}

.news .news-content .content-title {
  padding: 24px 0 32px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.news .news-content .content-description {
  font-weight: 400;
  line-height: 30px;
}

.news .news-content .content-author {
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding-top: 24px;
  display: flex;
}

.news .news-content .content-author > div {
  flex-direction: row;
  justify-content: start;
  display: flex;
}

.news .news-content .content-author > div > div {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.news .news-content .content-author .avatar {
  height: 56px;
  margin-right: 24px;
}

.news .news-content .content-author .author-name {
  font-weight: 500;
}

.news .news-content .content-author .author-position {
  color: #bbbcbd;
  font-weight: 400;
}

.news .news-content .news-img {
  max-width: 576px;
  max-height: 300px;
}

.news .news-content .quotes-img {
  height: 32px;
  margin-left: auto;
}

.subheader {
  color: #fcd3af;
  padding-bottom: 16px;
  font-weight: 300;
}

.sub_title {
  letter-spacing: -.4px;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
}

.read_more_wrapper {
  position: relative;
}

.read_more_wrapper .read_more_link {
  color: #fff;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.read_more_wrapper .read_more_link .read_more_hidden {
  display: none;
}

.read_more_wrapper .read_more_link img {
  height: 21px;
  margin-left: 8px;
}

.read_more_wrapper .link_underline {
  max-width: 419px;
  position: absolute;
  bottom: -8px;
}

@media (max-width: 1199.98px) {
  .common_section .logo_big {
    display: none;
  }

  .products .wiggle_arrow {
    right: 0;
  }

  .network .network_list li {
    flex-direction: column;
    flex: 0 33.3333%;
    justify-content: start;
    align-items: center;
    display: flex;
  }

  .hidden {
    display: none;
  }
}

@media (max-width: 1012px) {
  .products .wiggle_arrow {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .together .together_content .together_right_column, .together .together_content .together_left_column {
    max-width: 348px;
  }

  .together .together_content .together_right_column .right_link_underline, .together .together_content .together_left_column .left_link_underline {
    max-width: 108px;
    bottom: -4px;
  }

  .products .products_list .product_name {
    max-width: 348px;
  }

  .news .news-content .content-author {
    text-align: end;
    flex-direction: column;
    margin-left: auto;
  }

  .read_more_wrapper .read_more_link .read_more {
    display: none;
  }

  .read_more_wrapper .read_more_link .read_more_hidden {
    display: initial;
  }
}

@media (max-width: 823px) {
  .products .products_list {
    gap: 48px;
  }
}

@media (max-width: 767.98px) {
  .main .main_header {
    letter-spacing: -.48px;
    font-size: 48px;
    line-height: 60px;
  }

  .main .underline {
    max-width: 259px;
    bottom: 20px;
  }

  .together .together_content {
    flex-direction: column;
    align-items: baseline;
  }

  .together .together_content .around-arrows {
    display: none;
  }

  .together .together_content .together_right_column {
    padding-bottom: 0;
  }

  .together .together_content .together_left_column {
    padding-top: 40px;
  }

  .products {
    padding-bottom: 64px;
  }

  .products .products_list li img {
    width: 348px;
    height: 348px;
  }

  .about {
    flex-direction: column;
    padding-top: 0;
  }

  .about .stranlkraft-logo {
    width: 348px;
    margin: 0 auto;
  }

  .news .news-content {
    flex-direction: column;
  }

  .news .news-content .news-img {
    max-width: initial;
    max-height: initial;
    min-height: 178px;
  }
}

@media (max-width: 575.98px) {
  .together {
    padding-top: 32px;
  }

  .products .products_menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .products .products_menu li {
    padding-bottom: 24px;
  }

  .products .products_menu li:last-of-type {
    padding-bottom: 0;
  }

  .products .products_list li img {
    width: 272px;
    height: 272px;
  }

  .products .products_list li .product_name {
    max-width: 272px;
    max-height: 272px;
  }

  .overlay .main_subheader {
    padding-top: 15px;
  }

  .strahlkraft_text {
    display: none;
  }

  .more_about_link button .us_text {
    display: initial;
  }

  .network .network_list {
    flex-direction: column;
  }

  .about .stranlkraft-logo {
    max-width: 296px;
  }

  .about .about-list {
    flex-direction: column;
  }

  .about .about-list li:nth-of-type(2) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

/*# sourceMappingURL=index.28a27f96.css.map */

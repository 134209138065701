@import 'common/variables.scss';
@import 'common/mixins.scss';


.common_section {
  position: relative;
  overflow-x: clip;

  .logo_big {
    position: absolute;
    height: 2200px;
    top: -394px;
    right: -1397px;
  }
}


/*section main*/

.main {
  position: relative;

  .main_header {
    line-height: 100px;
    font-size: 100px;
    letter-spacing: -1px;
  }

  .underline {
    position: absolute;
    bottom: 5px;
  }

  ._light {
    font-weight: $light-font-weight;
  }

  h1:last-of-type {
    margin-bottom: 20px;
    position: relative;
    z-index: 7;
  }
}

.overlay {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url('../assets/pattern-wave.svg');
  background-position: center;
  background-repeat: repeat;

  .main_subheader {
    padding-top: 24px;
    padding-bottom: 51px;

    p {
      max-width: 676px;
      font-weight: $regular-font-weight;
      padding-bottom: 48px;
    }
  }
}

.more_about_link {
  text-decoration: none;

  button {
    cursor: pointer;
    border: none;
    background-color: $primary;
    border-radius: 2px;
    font-family: 'Lexend', sans-serif;
    font-weight: $medium-font-weight;
    font-size: 18px;
    @include flex-row(start, center);
    padding: 17px 24px 16px;
    color: $text-color-dark;

    img {
      margin-left: 8px;
      height: 20px;
      width: 21px;
    }

    .us_text {
      display: none;
    }
  }
}


/*section together*/

.together {
  padding-top: 125px;

  .together_content {
    @include flex-row(space-between, center);
    flex-wrap: wrap;
    position: relative;

    .around-arrows {
      position: absolute;
      top: -4%;
      left: 33%;
    }

    .together_right_column {
      max-width: 374px;
      padding-bottom: 120px;
      position: relative;

      .right_link_underline {
        position: absolute;
        bottom: -8px;
        max-width: 280px;
      }
    }

    .together_left_column {
      padding-top: 120px;
      max-width: 414px;
      position: relative;

      .left_link_underline {
        position: absolute;
        bottom: -8px;
        max-width: 384px;
      }
    }
  }


  .number_title {
    margin-bottom: -33px;
    font-size: 140px;
    line-height: 140px;
    color: $bg;
    font-weight: $bold-font-weight;
    text-shadow: -0   -2px 0 #2F2F32,
    0   -2px 0 #2F2F32,
    -0 2px 0 #2F2F32,
    0 2px 0 #2F2F32,
    -2px -0 0 #2F2F32,
    2px -0 0 #2F2F32,
    -2px 0 0 #2F2F32,
    2px 0 0 #2F2F32,
    -1px -2px 0 #2F2F32,
    1px -2px 0 #2F2F32,
    -1px 2px 0 #2F2F32,
    1px 2px 0 #2F2F32,
    -2px -1px 0 #2F2F32,
    2px -1px 0 #2F2F32,
    -2px 1px 0 #2F2F32,
    2px 1px 0 #2F2F32,
    -2px -2px 0 #2F2F32,
    2px -2px 0 #2F2F32,
    -2px 2px 0 #2F2F32,
    2px 2px 0 #2F2F32,
    -2px -2px 0 #2F2F32,
    2px -2px 0 #2F2F32,
    -2px 2px 0 #2F2F32,
    2px 2px 0 #2F2F32;
  }

  .together_subtitle {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.32px;
    font-weight: $bold-font-weight;

  }

  p {
    padding: 32px 0;
    line-height: 30px;
    font-weight: $regular-font-weight;
  }
}

/*section products*/

.products {
  padding-top: 128px;
  padding-bottom: 128px;
  position: relative;

  .wiggle_arrow {
    position: absolute;
    top: 140px;
    right: 140px;
  }

  .products_content_container {
    max-width: 576px;
    padding-top: 32px;
    padding-bottom: 80px;
    font-weight: $medium-font-weight;
    line-height: 30px;
  }

  .products_menu {
    @include flex-row(null, center);
    padding-bottom: 80px;

    .active {
      color: $primary;
    }

    li {
      padding-right: 48px;
      color: $text-secondary;
      font-weight: $light-font-weight;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .products_list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    li {
      flex: 1 1 calc((100% / 3) - 24px);

      img {
        max-width: 376px;
        max-height: 376px;
      }
    }

    .product_name {
      font-size: 24px;
      line-height: 30px;
      font-weight: $medium-font-weight;
      padding-top: 16px;
      padding-bottom: 8px;
    }

    .product_description {
      color: $text-secondary;
      font-weight: $regular-font-weight;
    }
  }
}

/*section about*/

.about {
  @include flex-row(space-between, null);
  padding-bottom: 64px;
  padding-top: 64px;

  .stranlkraft-logo {
    max-width: 576px
  }

  .about-subtitle {
    font-weight: $medium-font-weight;
    line-height: 30px;

    &:first-of-type {
      padding-top: 32px;
    }
  }

  .about-description {
    padding-top: 24px;
    font-weight: $regular-font-weight;
    line-height: 30px;
  }

  .about-list {
    @include flex-row(space-around, null);
    padding-bottom: 48px;
    padding-top: 48px;
    padding-left: 0;

    li {
      list-style-type: none;
      @include flex-column(null, center);

    }

    .item_image {
      font-size: 40px;
      line-height: 48px;
      color: $primary;
      font-weight: $light-font-weight;
    }

    .item_name {
      font-weight: $medium-font-weight;
    }
  }

}

/*section network*/

.network {

  & > div:first-of-type {
    text-align: center;
  }

  .network_list {
    @include flex-row(space-between, null);
    flex-wrap: wrap;
    padding-bottom: 32px;
    border-bottom: 1px solid $bg_lighter;
    padding-left: 0;

    li {
      list-style-type: none;

    }

    img {
      padding-top: 24px;
      max-height: 70px;
    }
  }
}

/*section news*/
.news {
  padding-top: 128px;
  padding-bottom: 128px;

  .news-content {
    display: flex;
    gap: 24px;

    & > div {
      flex: 1 1 calc((100% / 2) - 24px);
      @include flex-column(start, null);
      padding-top: 32px;
    }

    .content-title {
      padding: 24px 0 32px;
      font-size: 24px;
      line-height: 30px;
      font-weight: $medium-font-weight;
    }

    .content-description {
      line-height: 30px;
      font-weight: $regular-font-weight;
    }

    .content-author {
      padding-top: 24px;
      @include flex-row(space-between, end);

      & > div {
        @include flex-row(start, null);

        & > div {
          @include flex-column(space-between, null);
        }
      }

      .avatar {
        height: 56px;
        margin-right: 24px;
      }

      .author-name {
        font-weight: $medium-font-weight;
      }

      .author-position {
        font-weight: $regular-font-weight;
        color: $text-secondary;
      }

    }

    .news-img {
      max-width: 576px;
      max-height: 300px;
    }

    .quotes-img {
      margin-left: auto;
      height: 32px;
    }
  }
}


/*common styles*/

.subheader {
  color: $primary;
  font-weight: $light-font-weight;
  padding-bottom: 16px;
}

.sub_title {
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -0.4px;
  font-weight: $bold-font-weight;
}


.read_more_wrapper {
  position: relative;

  .read_more_link {
    @include flex-row(null, center);
    text-decoration: none;
    font-weight: $medium-font-weight;
    color: $text-color;

    .read_more_hidden {
      display: none;
    }

    img {
      margin-left: 8px;
      height: 21px;
    }
  }

  .link_underline {
    position: absolute;
    bottom: -8px;
    max-width: 419px;
  }
}


/*breakpoints*/
@media (max-width: 1199.98px) {
  .common_section {
    .logo_big {
      display: none;
    }
  }
  .products .wiggle_arrow {
    right: 0;
  }
  .network .network_list li {
    align-items: center;
    flex: 0 1 calc(100% / 3);
    @include flex-column(start, null);
  }

  .hidden {
    display: none;
  }
}

@media (max-width: 1012px) {
  .products .wiggle_arrow {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .together {
    .together_content {
      .together_right_column, .together_left_column {
        max-width: 348px;
      }

      .together_right_column {
        .right_link_underline {
          bottom: -4px;
          max-width: 108px;
        }
      }

      .together_left_column {
        .left_link_underline {
          bottom: -4px;
          max-width: 108px;
        }
      }
    }
  }

  .products .products_list .product_name {
    max-width: 348px;
  }
  .news .news-content .content-author {
    flex-direction: column;
    text-align: end;
    margin-left: auto;
  }

  .read_more_wrapper .read_more_link {
    .read_more {
      display: none;
    }

    .read_more_hidden {
      display: initial;
    }
  }
}

@media (max-width: 823px) {
  .products .products_list {
    gap: 48px
  }
}

@media (max-width: 767.98px) {

  .main {
    .main_header {
      font-size: 48px;
      letter-spacing: -0.48px;
      line-height: 60px;
    }

    .underline {
      max-width: 259px;
      bottom: 20px;
    }
  }

  .together {
    .together_content {
      flex-direction: column;
      align-items: baseline;

      .around-arrows {
        display: none;
      }

      .together_right_column {
        padding-bottom: 0;
      }

      .together_left_column {
        padding-top: 40px;
      }
    }
  }
  .products {
    padding-bottom: 64px;
  }
  .products .products_list {
    li img {
      width: 348px;
      height: 348px;
    }
  }
  .about {
    flex-direction: column;
    padding-top: 0;

    .stranlkraft-logo {
      width: 348px;
      margin: 0 auto;
    }
  }

  .news {
    .news-content {
      flex-direction: column;

      .news-img {
        max-width: initial;
        max-height: initial;
        min-height: 178px;
      }
    }
  }

}

@media (max-width: 575.98px) {

  .together {
    padding-top: 32px;
  }
  .products {
    .products_menu {
      flex-direction: column;
      align-items: flex-start;

      li {
        padding-bottom: 24px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    .products_list {

      li {
        img {
          width: 272px;
          height: 272px;
        }

        .product_name {
          max-width: 272px;
          max-height: 272px;
        }
      }
    }
  }

  .overlay .main_subheader {
    padding-top: 15px;
  }
  .strahlkraft_text {
    display: none;
  }
  .more_about_link button .us_text {
    display: initial;
  }
  .network .network_list {
    flex-direction: column;
  }

  .about {

    .stranlkraft-logo {
      max-width: 296px;
    }

    .about-list {
      flex-direction: column;

      li:nth-of-type(2) {
        padding-bottom: 24px;
        padding-top: 24px;
      }
    }
  }
}
